import React, { useState, useEffect } from "react";
import "./style.css";
import categ11 from "../../images/categ/categorie1.png";
import categ22 from "../../images/categ/categorie2.png";
import categ33 from "../../images/categ/categorie3.png";
import categ44 from "../../images/categ/categorie4.png";
import { Button } from "@mui/material";
import Footer from "../../Components/Footer";
import Carousel from "../../Components/Carousel";
import ReactGA from "react-ga";
import ScrollPage from "../../Components/scrollpageUp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SelctionSemaine from "../../Components/SelectionSemaine";
import VenteFlash from "../../Components/VenteFlashHome";
import NewHome from "../../Components/NouveauteHome";
import BanniereHome from "../../Components/BanniereHome";
import { useInView } from "framer-motion";
import { useRef } from "react";
import SelectionProduct from "../../Components/SelectionProduct";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function Home() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInView = useInView(ref1, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  const isInView3 = useInView(ref3, { once: true });
  
  useEffect(() => {
    const updateItemsPerPage2 = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage2(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage2(3);
      } else {
        setItemsPerPage2(2);
      }
    };
    updateItemsPerPage2();
    window.addEventListener("resize", updateItemsPerPage2);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage2);
    };
  }, []);
  useEffect(() => {
    const updateItemsPerPage1 = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage1(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage1(2);
      } else {
        setItemsPerPage1(2);
      }
    };
    updateItemsPerPage1();
    window.addEventListener("resize", updateItemsPerPage1);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage1);
    };
  }, []);
  const [itemsPerPage1, setItemsPerPage1] = useState(6);
  const [itemsPerPage2, setItemsPerPage2] = useState(4);
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-arrow custom-next"
        onClick={onClick}
        style={{ border: "1px solid black" }}
      >
        <ArrowRightAltIcon fontSize="small" />
      </div>
    );
  };
  const settings2 = {
    slidesToShow: itemsPerPage2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    className: "custom-slider",
  };
  const settings1 = {
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    speed: 600,
    slidesToShow: itemsPerPage1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    className: "custom-slider",
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2500);

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, []); // Empty dependency array means this effect runs once after the component mounts
  useEffect(() => {
    const track_id = "G-T6622GHTPT";
    ReactGA.initialize(track_id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleLeave = () => {
    setHoveredIndex(null);
  };
  const [itemsPerPage, setItemsPerPage] = useState(4);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(2);
      }
    };
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  const shouldEnableSlider = window.innerWidth < 700;
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    className: "custom-slider",
  };

  return (
    <div className="home">
      <ScrollPage />
      <div className="homeContainer">
        <React.Fragment>
          <Dialog
            sx={{ zIndex: 99999999999999 }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Poppins",
                textAlign: "",
                fontSize: { lg: "18px", md: "16px", sm: "16px", xs: "15px" },
              }}
              className="dialogPromoTitle"
            >
              Bienvenue chez Ebuy
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <a href="/bons-plans/duo%20coiffure">
              <DialogContent className="custom-dialog"></DialogContent>
            </a>
            <DialogActions>
              <a href="/bons-plans/duo%20coiffure">
                <Button
                  size="small"
                  color="warning"
                  sx={{ fontFamily: "Poppins" }}
                  onClick={handleClose}
                >
                  profiter!
                </Button>
              </a>
            </DialogActions>
          </Dialog>
        </React.Fragment>

        <Carousel />
        {/*----------------vente flash----------------*/}
        <VenteFlash  />
        {/*----------------vente flash----------------*/}
        {/*----------------La sélection de la semaine----------------*/}
        <SelctionSemaine />
         {/*----------------La sélection des produits----------------*/}
         <SelectionProduct />
        {/*----------------categorie----------------*/}
        <div className="catHome" ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateY(200px)",
            opacity: isInView2 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
          <Slider {...settings2}>
            {categories.map((item) => (
              <div className={`images-zoom-container-home`}>
                <a href={`/collections/${item}`}>
                  <img
                    src={require(`../../images/categ/${item}.png`)}
                    alt={item}
                    className="catImgHome"
                  />
                </a>
              </div>
            ))}
          </Slider>
        </div>
         
        {/*----------------banniereHome----------------*/}
        <BanniereHome/>
        {/*----------------nouveaté----------------*/}
        <NewHome  />
        {/*----------------nouveaté----------------*/}
        <h2   ref={ref1}
          style={{
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }} className="bigTitleSlide2">Nos Packs et promos</h2>
        <div
          ref={ref1}
          style={{
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}
        >
          <Slider {...settings1}>
            {itemData.map((item) => (
              <div className={`images-zoom-container-home`}>
                <a href={`/bons-plans/${item}`}>
                  <img
                    src={require(`../../images/promos/${item}.jpeg`)}
                    alt={item}
                    className="promoImgHome"
                  />
                  <Button
                    endIcon={<ArrowRightAltIcon />}
                    color="warning"
                    className="promoButton"
                  >
                    Profiter
                  </Button>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/*----------------categorie+photos----------------*/}
      <div style={{ backgroundColor: "#F0E7D8", marginTop: "10px" ,  transform: isInView3 ? "none" : "translateY(200px)",
            opacity: isInView3 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"}} ref={ref3} >
        {shouldEnableSlider && (
          <Slider {...settings} className="categSlider">
            <div
              className={`categDivAnimation ${
                hoveredIndex === 1 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(1)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/electromenager">
                <img className="catImg" src={categ11} alt="" />
              </a>
              <h2>Simplicité et efficacité dans chaque foyer</h2>
              <p>
                Bienvenue dans notre gamme d'appareils électroménagers, où la
                simplicité rencontre l'efficacité. Nous nous engageons à rendre
                la vie quotidienne plus facile et plus agréable avec des
                produits de qualité.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 4 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(4)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/beaute & sante">
                <img className="catImg" src={categ44} alt="" />
              </a>
              <h2>Beauté & Soin : Excellence pour Elle et Lui</h2>
              <p>
                Découvrez notre gamme Beauté & Soin, avec un large choix
                d'appareils pour cheveux, corps et plus. Des solutions pratiques
                pour femmes et hommes, offrant qualité et performance pour une
                routine de soin parfaite.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 2 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(2)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/luminaire">
                <img className="catImg" src={categ22} alt="" />
              </a>
              <h2>Illuminez votre style, Créez l’ambiance parfaite</h2>
              <p>
                Bienvenue dans notre univers lumineux. De suspensions
                sophistiquées à des lampes de table modernes, notre gamme de
                luminaires crée une ambiance unique dans chaque coin de votre
                maison.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 3 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(3)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/mobilier">
                <img className="catImg" src={categ33} alt="" />
              </a>
              <h2>
                Mobilier ingénieux : Style fonctionnel pour un quotidien
                simplifié
              </h2>
              <p>
                Explorez des solutions de rangement astucieuses, des meubles
                adaptables à vos besoins changeants, et des créations qui
                allient forme et fonction. Notre gamme de mobilier ingénieux
                vise à simplifier votre vie quotidienne tout en apportant une
                touche moderne et esthétique à votre intérieur.
              </p>
            </div>
          </Slider>
        )}

        {/* Afficher les divs individuellement sur grand écran */}
        {!shouldEnableSlider && (
          <div className="categDiv2">
            <div
              className={`categDivAnimation ${
                hoveredIndex === 1 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(1)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/electromenager">
                <img className="catImg" src={categ11} alt="" />
              </a>
              <h2>Simplicité et efficacité dans chaque foyer</h2>
              <p>
                Bienvenue dans notre gamme d'appareils électroménagers, où la
                simplicité rencontre l'efficacité. Nous nous engageons à rendre
                la vie quotidienne plus facile et plus agréable avec des
                produits de qualité.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 4 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(4)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/beaute & sante">
                <img className="catImg" src={categ44} alt="" />
              </a>
              <h2>Beauté & Soin : Excellence pour Elle et Lui</h2>
              <p>
                Découvrez notre gamme Beauté & Soin, avec un large choix
                d'appareils pour cheveux, corps et plus. Des solutions pratiques
                pour femmes et hommes, offrant qualité et performance pour une
                routine de soin parfaite.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 2 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(2)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/luminaire">
                <img className="catImg" src={categ22} alt="" />
              </a>
              <h2>Illuminez votre style, Créez l’ambiance parfaite</h2>
              <p>
                Bienvenue dans notre univers lumineux. De suspensions
                sophistiquées à des lampes de table modernes, notre gamme de
                luminaires crée une ambiance unique dans chaque coin de votre
                maison.
              </p>
            </div>
            <div
              className={`categDivAnimation ${
                hoveredIndex === 3 ? "hovered" : ""
              }`}
              onMouseEnter={() => handleHover(3)}
              onMouseLeave={handleLeave}
            >
              <a href="/collections/mobilier">
                <img className="catImg" src={categ33} alt="" />
              </a>
              <h2>
                Mobilier ingénieux : Style fonctionnel pour un quotidien
                simplifié
              </h2>
              <p>
                Explorez des solutions de rangement astucieuses, des meubles
                adaptables à vos besoins changeants, et des créations qui
                allient forme et fonction. Notre gamme de mobilier ingénieux
                vise à simplifier votre vie quotidienne tout en apportant une
                touche moderne et esthétique à votre intérieur.
              </p>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
const itemData = [
  "Essentiels du matin",
  "kit cuisine moderne",
  "epilation et soin visage",
  "cuisine facile",
  "kit soin complet",
  "soin et elegance",
  "soin visage et bikini",
  "sechage et lissage",
  "duo coiffure",
];
const categories = [
  "electromenager",

  "beaute & sante",

  "mode & accessoires",
  "informatique & gaming",
  "mobilier",
  "luminaire",
];
export default Home;
