/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import Product100Tri from '../../Components/Product100';
import ScrollToTop from '../../Components/scrollup';
export default function Product50() {
  return (
    <div className='products100' id='produits'>
    
    <h2 className="pathTitle" key={Math.floor(Math.random() * 1)}>
    nos petits prix 
            </h2>
      <div className='product100'>
        <ScrollToTop/>
       
        <div className='product100Contain'>
       
          <Product100Tri moincher={80} />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
