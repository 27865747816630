/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import UseFetch from "../../hook/useFetch";
import { Button, Pagination } from "@mui/material";
import "./style.css";
import SwapVertIcon from '@mui/icons-material/SwapVert';
export default function Product100Tri(moincher) {
  const {data,loading}=UseFetch("https://ebuy-backend-two.vercel.app/api/article");
  const produits = Array.from(data);
 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [sortBy, setSortBy] = useState("nameAsc");
  const handleSortToggle = () => {
    setSortBy(sortBy === 'priceAsc' ? 'priceDesc' : 'priceAsc');
  };
  
  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(12);
      } else if (windowWidth >= 700) {
        setItemsPerPage(12);
      } else {
        setItemsPerPage(10);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);
  const filteredProducts = produits.filter((produit) => {
    return (produit.prix) < moincher.moincher;
  });
  

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  let sortedProducts = [...filteredProducts]; // Copie du tableau pour ne pas modifier l'original
  if(sortBy === "priceAsc"){
    sortedProducts.sort((a,b) => (a.prix > b.prix) ? 1 : ((b.prix > a.prix) ? -1 : 0));
  }
  else if(sortBy === "priceDesc"){
    sortedProducts.sort((a,b) => (a.prix < b.prix) ? 1 : ((b.prix < a.prix) ? -1 : 0));
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
    
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage.toString());
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };
  const currentCards = sortedProducts.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  return loading ? (
    <div className="loader"></div>
    ) : (
    <div >
      <div className="triDiv2">
        <Button sx={{fontFamily:'Poppins'}} size="small" variant="outlined" color="warning" onClick={() => handleSortToggle()} endIcon={<SwapVertIcon/>}>Prix</Button>
        </div>
        {totalPages > 1 && ( 
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          shape="rounded"
          variant="outlined"
          onChange={(event, newPage) => handlePageChange(newPage)}
          color="warning"
          size="small"
        />
        
      </div>
      )}
      <div className="productmarquePages">
        {currentCards.map((produit, index) => (
          <ProductCard
            key={produit.article + index}
            categorie={produit.categorie}
            subcategorie={produit.subcategorie}
            article={produit.article}
            prix={produit.prix}
            colors={produit.colors}
            prixFlash={produit.prixFlash}
            brand={produit.brand}
            famille={produit.famille}
            stock={produit.stock}
          />
        ))}
      </div>
      {totalPages > 1 && ( 
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          shape="rounded"
          variant="outlined"
          onChange={(event, newPage) => handlePageChange(newPage)}
          color="warning"
          size="small"
        />
        
      </div>
      )}
      </div>
  );
}
