import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ban1 from "../../images/selection produits/1.jpeg";
import ban2 from "../../images/selection produits/2.jpeg";
import ban3 from "../../images/selection produits/3.jpeg";
import ban4 from "../../images/selection produits/4.jpeg";
import ban5 from "../../images/selection produits/5.jpeg";
import ban6 from "../../images/selection produits/6.jpeg";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function SelectionProduct() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });

      return(
        <div ref={ref2} style={{ 
          transform: isInView2 ? "none" : "translateY(200px)",
          opacity: isInView2 ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>
        
    <div className="categorieProduct">
      <div className="carousselDivHomeProduct">
        <div className="carouselDivHProduct">
          <a
            href="/produit/electromenager/preparation%20culinaire/Blender%20shaketake%20rouge%20AR1032RED"
            target="_blank"
          >
            <img src={ban1} alt="weeklypromo" className="banPromoPicProduct" />
          </a>
          <div className="carouselDivVProduct">
            <a
              href="/produit/beaute%20&%20sante/coiffure/Seche%20cheveux%20compact%20Ocean%20HC35"
              target="_blank"
            >
              <img
                src={ban2}
                alt="weeklypromo"
                className="banPromoPic2product"
              />
            </a>
            <a
              href="/produit/electromenager/preparation%20culinaire/Batteur%20grenat%20AR1023GR"
              target="_blank"
            >
              <img
                src={ban3}
                alt="weeklypromo"
                className="banPromoPic2product"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="carousselDivHomeProduct">
        <div className="carouselDivHProduct">
          <a
            href="/produit/beaute%20&%20sante/rasoir%20et%20tendeuse/SilkFinish%20BIKINI%203%20en%201%20rose%20FG1100"
            target="_blank"
          >
            <img src={ban4} alt="weeklypromo" className="banPromoPicProduct" />
          </a>
          <div className="carouselDivVProduct">
            <a
              href="/produit/electromenager/hygiene%20et%20article%20maison/Fer%20à%20vapeur%20AR684"
              target="_blank"
            >
              <img
                src={ban5}
                alt="weeklypromo"
                className="banPromoPic2product"
              />
            </a>
            <a
              href="/produit/electromenager/cafe%20et%20petit%20dejeuner/Cafetiere%20individuelle%20AR3058"
              target="_blank"
            >
              <img
                src={ban6}
                alt="weeklypromo"
                className="banPromoPic2product"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
 
  </div>

)
}