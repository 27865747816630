/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import ScrollToTop from '../../Components/scrollup';
import SaisonProduct from '../../Components/SaisonProduct';
export default function ProductOfSeason() {

  return (
    <div className='products100' id='produits'>
        <h2 className="pathTitle" key={Math.floor(Math.random() * 1)}>
              selection du mois
            </h2>
      <div className='product100'>
        <ScrollToTop/>
       
        <div className='product100Contain'>
       
          <SaisonProduct saison='summer' />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
